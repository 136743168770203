@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  /* user-select: none !important; */
  -webkit-tap-highlight-color: rgba(
    0,
    0,
    0,
    0
  ); /* handling-the blue effect on mobile device--  */
}

/* scroll removed from input type=number */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background-color: #e4e4e451;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(209, 132, 242, 0.589);
  border-radius: 20px;
}

.App {
  @apply bg-[#F4F4F4] min-h-[100dvh] flex text-[#000000] overflow-hidden;
}
/* /* dashboard */
.dashboard-wrapper,
.history-wrapper {
  @apply p-4 pb-0 relative w-full min-h-full overflow-auto;
}

.chat-box {
  @apply max-h-[calc(100dvh-138px)] lg:max-h-[calc(100dvh-150px)] p-1 rounded-lg overflow-auto md:w-[85%]  mx-auto mb-3 leading-6 tracking-wide;
}
.chat-box-new {
  @apply lg:max-h-[calc(100dvh-108px)];
}

.chat-form {
  @apply md:w-[82%] w-[90%] absolute left-[50%] -translate-x-[50%] bottom-0 sm:mb-6 mb-5;
}
.chat-input {
  box-shadow: 0px 2px 2px 0px #ddd;
  @apply w-[100%] m-auto bg-[#fff]  rounded outline-none lg:px-4 lg:pr-[3.4rem] px-2 pr-[3.4rem] py-3;
}
.mic {
  @apply absolute right-9 mr-5 top-[16%];
}
.inp-btn svg {
  box-shadow: 0px 2px 2px 0px #ddd;
  @apply rounded transition-all hover:scale-100;
}
.inp-btn,
.loader {
  @apply absolute right-4 top-[50%] -translate-y-[50%];
}
.chat {
  @apply w-full my-4 mb-6 flex overflow-auto flex-col gap-2 whitespace-pre-wrap;
}
.user-chat {
  background: linear-gradient(180deg, #c1c2db68 0%, #c3aeeb37 100%);

  @apply sm:p-4 p-2 flex items-start sm:gap-4 gap-2 rounded relative;
}

.bot-chat {
  background: linear-gradient(180deg, #c1c2db68 0%, #c3aeeb37 100%);

  @apply flex flex-col gap-6 rounded sm:p-4  p-2 relative;
}
.bot-top-chat {
  @apply flex items-start sm:gap-4 gap-2 rounded;
}

.chat-history {
  @apply w-full my-2 flex overflow-auto flex-col gap-2 whitespace-pre-wrap;
}
.bot-chathistory-wrapper {
  background: linear-gradient(180deg, #c1c2db68 0%, #c3aeeb37 100%);

  @apply flex flex-col gap-6 rounded-md relative mb-4 pb-2;
}

.user-history-chat,
.bot-history-chat {
  @apply sm:p-4 p-3 flex items-start rounded-md  sm:gap-4 gap-1  relative;
}

.user-history-chat {
  background: linear-gradient(180deg, #c1c2db68 0%, #c3aeeb37 100%);
}

.bot-history-chat {
  @apply mb-5;
}
.query,
.response {
  @apply lg:max-w-[85%] max-w-[80%] text-black text-sm leading-6 break-words;
}

.options {
  @apply absolute sm:right-3 right-2 text-[#777777];
}
.doctor-card-wrapper {
  @apply p-1 flex gap-3 justify-center items-center flex-wrap mt-8;
}
.doctor-card {
  background: linear-gradient(180deg, #c1c2db68 0%, #c3aeeb37 100%);
  @apply relative  flex flex-col justify-between h-[22rem] w-[15rem] rounded-lg shadow-2xl overflow-hidden;
}
.banner {
  @apply w-full min-h-[23%] bg-slate-400;
}
.doctor-img {
  @apply sm:h-24 sm:w-24 h-20 w-20 object-contain absolute left-[50%] -translate-x-[50%] top-8 z-10 rounded-full bg-slate-900;
}
.card-details {
  @apply flex flex-col gap-3 justify-between mt-14 sm:p-2 p-1 text-xs;
}
.card-detail {
  @apply flex justify-between items-start gap-1 text-right;
}

.refresh-btn {
  @apply w-max md:px-6 px-3 py-2 flex items-center gap-1 bg-[#1A1A1A] border-[#383838] absolute bottom-14 left-[50%] -translate-x-[50%] sm:mb-6 mb-5;
}
/*  */
.sidebar-wrapper {
  @apply bg-[#FFFFFF] shadow-xl md:min-w-[290px] sm:min-w-[250px] sm:h-auto max-h-[100vh] overflow-auto h-0 w-0 sm:-translate-x-0 -translate-x-[500px] transition-all flex justify-between flex-col gap-4 p-1 text-[15px];
}

.sidebar-mobile-wrapper {
  @apply w-[97%] h-[100dvh] backdrop-blur-[3px]  absolute z-40 transition-all  sm:-translate-x-[5000px];
}
.sidebar-mobile {
  @apply w-[275px] h-full bg-[#FFFFFF] shadow-xl overflow-auto flex justify-between flex-col gap-4 p-1 pr-3 text-[15px] absolute;
}
.close-sidebar {
  @apply absolute right-0 m-1 cursor-pointer;
}
.sidebar-crumbs {
  @apply m-2;
}
.user-name-sidebar {
  @apply capitalize;
}

.head-title {
  @apply text-2xl w-full text-center font-bold tracking-wider text-[#D4C0F8];
}

.logo-text {
  font-family: "Poltawski Nowy";
}
.nav-item {
  @apply cursor-pointer transition-all w-full flex items-center gap-3 rounded p-3 hover:bg-[#d4c0f8ba];
}
.nav-text {
  @apply font-medium tracking-wide;
}
.new-chat-btn,
.view-profile-btn,
.recommend-btn,
.signup-btn,
.signin-btn {
  box-shadow: 0px 2px 2px 0px #ddd;

  @apply w-full rounded p-2 my-2 bg-[#D4C0F8] border border-[#D4C0F8] text-[#000000] hover:bg-[#ccb0ff] hover:text-black transition-all mx-auto font-bold;
}
.recommend-btn {
  @apply mr-[30px];
}

/* .recentBox {
  @apply max-h-[160px] md:max-h-[168px] lg:max-h-[180px] xl:max-h-[240px] 2xl:max-h-[400px] 3xl:max-h-[530px] overflow-auto;
} */
.recentItem {
  @apply cursor-pointer py-2 px-1 rounded hover:bg-[#d4c0f8ba];
}
/* .sidebar-lower {
  @apply lg:absolute w-full lg:bottom-1;
} */
.user-card {
  background: linear-gradient(180deg, #bfc1ff77 0%, #c3aeeb4c 100%);
  @apply text-[#000000] flex justify-between items-center relative border rounded-[5px] p-3 mb-2;
}
.drop {
  background: linear-gradient(180deg, #bfc1ff77 0%, #c3aeeb4c 100%);
  @apply flex justify-center md:px-6 px-3 py-2 gap-1 cursor-pointer items-center text-sm absolute bottom-[3.8rem] right-2 z-20 border rounded backdrop-blur-md;
}
.logout-btn {
  @apply flex gap-1  rounded w-max;
}

/*  */

.doctorlist-wrapper {
  @apply sm:p-4 p-1 max-w-full flex flex-col gap-4;
}
.doctors-input-wrapper {
  @apply sm:w-96 w-full px-6 relative flex items-center;
}
.doctor-search-input,
.signup-input,
.signin-input {
  @apply w-full bg-transparent border-[1px] border-[#D4C0F8] pl-10 py-2 rounded-[4px];
}
.search-icon {
  @apply absolute top-[50%] -translate-y-[50%] left-[2.5rem];
}

.selectDoctor-btns {
  @apply flex gap-2 sm:p-4 p-2 mt-3;
}
.selectDoctor-btn {
  background: linear-gradient(180deg, #bfc1ff77 0%, #c3aeeb4c 100%);
  @apply p-3 rounded;
}
.table-wrapper {
  @apply md:p-5 p-2 flex flex-col max-h-[calc(100dvh-240px)] overflow-auto;
}
.doctor-detail {
  background: linear-gradient(180deg, #bfc1ff77 0%, #c3aeeb4c 100%);

  @apply flex items-center md:px-8 px-4 shadow-md rounded gap-6 py-4 [&>*:nth-child(3)]:sm:min-w-[280px] w-full min-w-max my-1;
}

.table-col {
  @apply flex flex-col gap-1 break-words  min-w-[170px] sm:max-w-[140px] max-w-[120px];
}

/* DoctorProfile */

.doctor-profile-wrapper,
.appointment-wrapper {
  @apply flex flex-col gap-4 lg:p-6 p-1 tracking-wide sm:max-h-[98vh] max-h-[85vh] overflow-auto;
}
.doctor-profile-box {
  background: linear-gradient(180deg, #bfc1ff77 0%, #c3aeeb4c 100%);

  @apply flex flex-col gap-6 rounded-md md:mx-4 mx-2 py-6 lg:px-10 px-2;
}

.profile {
  @apply flex sm:flex-row flex-col items-center gap-10;
}
.basic-details {
  @apply flex flex-col sm:items-start items-center sm:text-start text-center gap-1;
}
.other-details {
  @apply mt-4 flex flex-col text-sm sm:text-base lg:gap-4 gap-2;
}
.detail {
  @apply gap-1;
}

/* Appointment */
.appointment-wrapper {
  @apply relative h-full;
}
.head {
  @apply p-2 md:px-8 flex items-center justify-between;
}
.book-appointment-btn,
.landing-login-btn {
  box-shadow: 0px 2px 2px 0px #ddd;

  @apply w-max rounded text-[#222222] font-semibold bg-[#D4C0F8] py-2 sm:px-6 px-2 transition-all hover:scale-105 active:scale-95;
}
.appointment-coming-soon {
  @apply w-full h-[100vh] z-20 absolute inset-0  backdrop-blur-[2px];
}
.coming-soon-wrapper {
  @apply h-full w-[90%] mx-auto relative flex items-center justify-center;
}

.animated-text {
  background: linear-gradient(135deg, #14ff89, #ffe600, #00ff1e);
  letter-spacing: 1px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animate 1s linear infinite;
  padding: 20px;
}

@keyframes animate {
  100% {
    filter: hue-rotate(360deg);
  }
}

/* Signup */
.signup-wrapper,
.signin-wrapper {
  @apply sm:p-4 p-2 w-full min-h-full flex items-center justify-center;
}
.signup-input-wrapper,
.signin-input-wrapper {
  @apply relative h-full w-full;
}
.signup-icon,
.signin-icon {
  @apply absolute top-[50%] -translate-y-[50%] left-2;
}
.form-wrapper {
  background: linear-gradient(180deg, #dadcfd29 0%, #dbc9fd1e 100%);

  @apply flex sm:flex-row flex-col-reverse items-center sm:gap-6 gap-3  rounded-lg sm:p-4 p-2 max-w-3xl md:w-[80%] lg:w-[70%] xl:w-[60%] w-[90%] shadow-lg;
}
.signup-form,
.signin-form {
  @apply flex flex-col gap-4 sm:w-[80%] w-[100%] sm:p-8 p-4 rounded-md;
}

.signup-input,
.signin-input,
.doctor-search-input {
  @apply outline-none focus:outline-[0.4px] focus:outline-[#D4C0F8] focus:border-transparent;
}
.signin-info,
.signup-info {
  @apply w-full text-right text-sm text-[#777777];
}

.signup-img {
  @apply lg:!flex !hidden;
}

.link {
  @apply cursor-pointer text-blue-400  transition-all hover:text-purple-700;
}
.error-message {
  @apply text-xs text-red-500;
}
/*  */
.appointment-book-wrapper {
  @apply w-full h-full sm:p-4 p-1;
}

/* Prompts */
.prompts-wrapper {
  @apply overflow-auto lg:w-[80%] lg:text-base text-sm w-full p-2 mt-6 mx-auto flex flex-col items-center text-center gap-6;
}
.title {
  @apply text-3xl my-4 font-bold tracking-wider text-[#D4C0F8];
}
.prompt-box {
  @apply flex flex-col gap-4 mb-4;
}
.prompt {
  background: linear-gradient(180deg, #bfc1ff77 0%, #c3aeeb4c 100%);
  @apply rounded-md p-3 cursor-pointer transition hover:scale-95;
}
/*  */
/* ?toggler */
.toggler {
  @apply w-full sm:hidden flex items-center p-2 fixed z-50 cursor-pointer;
}
.header {
  @apply backdrop-blur-[5px] border-b border-b-[#d4c0f8a6];
}
/*  */
.landingPage-wrapper {
  @apply w-full mx-auto h-full relative select-none;
}
.landing-nav {
  @apply fixed left-0 right-0 z-50 backdrop-blur-[10px] bg-[#111111] border-b border-b-[#D4C0F8] bg-opacity-40  py-2 lg:px-8 md:px-6 sm:px-4 px-2 flex items-center justify-between;
}
.landing-login-btn {
  @apply sm:flex hidden;
}
.nav-dropdown {
  @apply sm:hidden absolute right-2 top-16 bg-[#252527]  flex flex-col items-center gap-4 w-[150px] rounded-lg shadow-md p-4 z-50 animate-[fadeIn];
}
.nav-dropdown {
  animation: fadeIn 0.4s ease-in-out;
}
@keyframes fadeIn {
  from {
    transform: translateY(20px);
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}
.lists {
  @apply w-full text-center rounded transition-all active:scale-95 cursor-pointer hover:text-[#D4C0F8];
}
.landing-login-btn-mobile {
  @apply w-full rounded text-[#222222] font-semibold bg-[#D4C0F8] p-2 transition-all hover:scale-105 active:scale-95;
}
.hero {
  @apply py-2 lg:pl-8 md:pl-6 sm:pl-4 px-4 flex sm:flex-row flex-col  sm:gap-20 gap-6 items-center sm:mt-4 mt-20 leading-10 tracking-wide p-2 sm:min-h-[98vh] relative bg-landing-hero bg-fixed;
}
.hero-title {
  @apply flex flex-col sm:gap-6 gap-3 max-w-2xl z-30 backdrop-blur-[1px] rounded-lg 2xl:ml-24;
}
.hero-img-wrapper {
  @apply opacity-75 sm:absolute sm:right-0 overflow-hidden sm:h-[70vh] h-[45vh];
}
.hero-img-wrapper img {
  @apply h-full w-full sm:object-cover object-contain;
}
.tryNow-btn {
  @apply sm:w-max w-full rounded text-[#222222] font-semibold bg-[#D4C0F8] px-4 transition-all hover:scale-105 active:scale-95;
}
.try-connect-wrapper {
  @apply absolute z-20 inset-0 w-full h-full backdrop-blur-[3px] grid place-content-center;
}
.try-connect-btn {
  @apply sm:w-max py-4  rounded text-[#222222] font-semibold bg-[#D4C0F8] px-12 transition-all hover:scale-105 active:scale-95;
}

.matrix-bg {
  @apply absolute inset-0;
}
.matrix-bg-img {
  @apply h-full;
}

.try-live-section {
  @apply sm:my-20 my-10 px-4 flex flex-col items-center sm:gap-8 gap-5 text-center;
}
.try-chat-box {
  @apply h-[75vh] overflow-auto md:w-[85%] w-[90%] mx-auto mb-[5rem] leading-6 tracking-wide;
}
.try-gpt-box {
  @apply shadow-custom-shadow rounded-lg text-left min-h-[80vh] relative;
}
.try-gpt-title {
  @apply flex flex-col sm:gap-6 gap-4 sm:text-start text-center sm:py-8 py-2;
}

.feature-section {
  @apply flex flex-col justify-center items-center py-2 lg:px-8 md:px-6 px-4 sm:mt-10 my-2 pt-10 sm:gap-10 gap-4;
}
.feature-boxes {
  @apply flex sm:flex-row flex-col justify-center sm:gap-4 gap-2 lg:h-[580px];
}
.feature-box {
  @apply relative max-w-lg text-left flex flex-col md:gap-5 gap-3 bg-[#1D1D1D] rounded-xl overflow-hidden;
}
.feature-box-pattern {
  @apply absolute top-0 right-0 h-[30%] w-[30%];
}
.feature-title {
  @apply flex flex-col sm:w-[80%] w-full sm:gap-6 gap-4 sm:p-6 p-3 z-10;
}
.feature-box-pattern-img {
  @apply h-full w-full object-contain rotate-180;
}
.feature-box-img {
  @apply opacity-95 md:absolute bottom-0;
}

.feature-side-gradient,
.feature-card-side-gradient {
  width: 100%;
  height: 150px;
  background: linear-gradient(
    90deg,
    #d4c0f8 -136.68%,
    rgba(233, 254, 83, 0) 187.68%
  );
  filter: blur(200px);
  @apply absolute -left-[400px] top-[50%] -translate-y-[50%] sm:flex hidden;
}
.feature-cards {
  @apply flex flex-col gap-3 relative max-h-full;
}
.feature-card-side-gradient {
  @apply absolute h-[200px] inset-0 top-[50%] -right-[200px] opacity-90 flex;
}
.feature-card {
  @apply w-full md:max-w-[400px] 2xl:max-w-[500px] bg-[#1D1D1D] h-1/3 md:p-4 p-2 rounded-lg z-20 backdrop-blur-[5px];
}
.card-icons {
  @apply lg:w-12 lg:h-12 w-10 h-10;
}
.perfect-for-section {
  @apply sm:pt-12 pt-6 flex flex-col gap-4;
}
.perfect-for-box {
  background-size: 100% 100%;
  @apply flex lg:justify-center bg-perfect-for-bg  sm:py-24 py-12 px-4 gap-4 overflow-auto;
}

.perfect-for-icon-img {
  @apply h-14 w-14  mx-auto object-contain text-center;
}
.box-card {
  @apply sm:p-6 sm:py-10 p-3 min-w-[350px] flex flex-col gap-3 bg-[#1D1D1D] rounded-lg;
}

.try-gpt-bookDemo {
  @apply grid place-items-center sm:py-16 py-12 px-3 bg-landing-hero;
}
.landing-footer {
  @apply flex flex-col sm:gap-4 gap-2 lg:px-8 md:px-6 sm:px-4 px-2 py-4;
}
.footer-upper {
  @apply flex sm:flex-row flex-col gap-2 justify-between items-center;
}
.footer-lower {
  @apply flex flex-col items-center gap-2 my-2;
}

.landingPage-wrapper ul li {
  @apply hover:text-[#D4C0F8] cursor-pointer transition-all;
}
.separator {
  @apply h-[2px] w-[90%] mx-auto opacity-50 bg-[#D4C0F8] rounded-[100%];
}

/*  */
.bookDemo-wrapper {
  @apply flex sm:flex-row flex-col gap-4 bg-[#1D1D1D] rounded-lg max-w-3xl md:w-[80%] lg:w-[70%] xl:w-[60%] w-[90%] md:px-6 md:py-10 p-3;
}

.bookDemo-form {
  @apply w-full flex flex-col gap-4 sm:p-0 p-1;
}
.bookDemo-btn {
  @apply sm:w-max w-full rounded p-2 my-2 bg-transparent border border-[#D4C0F8] text-[#D4C0F8] hover:bg-[#D4C0F8] hover:text-black transition-all font-bold;
}

/*  */

/* utils */
.bg-primary {
  @apply bg-[#D4C0F8];
}
.bg-secondary {
  @apply bg-[#1C1D23];
}

.text-primary {
  @apply text-[#000000];
}

.text-secondary {
  @apply text-[#777777];
}
.text-light {
  @apply text-[#94a3b8];
}
.text-footer {
  @apply text-[#3D3D3D];
}
.border-light {
  @apply border-[#c1b3dc5f];
}
.border-primary {
  @apply border-[#D4C0F8];
}

/* .blur-text {
  @apply blur-[2px];
} */
.s-p {
  @apply lg:px-3 md:px-2 py-1 cursor-pointer;
}

.flxCol {
  @apply flex flex-col gap-2;
}
.text-para {
  @apply text-sm text-[#A8A8A8];
}

.icons {
  @apply cursor-pointer h-4 transition-all active:scale-110;
}

.sidebar-icons {
  @apply h-6;
}

.back-btn-wrapper {
  @apply w-full px-4 py-2  sm:flex hidden;
}

.active {
  @apply bg-[#d4c0f8ba];
}
.speaker-animation {
  animation: speakeranimation 1.2s infinite ease-in-out;
}
@keyframes speakeranimation {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1.15);
  }
}

/* custom styles */
.response ul,
.response ol {
  list-style: decimal;
}

.response li {
  margin-bottom: 10px;
  margin-left: 1.2rem;
}

.response h3,
.response h4 {
  margin: 0.5rem auto;
}

p {
  margin-bottom: 10px;
}
strong {
  color: #000;
}
a {
  color: blue;
}
a:hover {
  text-decoration: underline;
}

/*  */
